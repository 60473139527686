<template>
  <div>
    <b-card title="Rearrange assets">
      <b-alert
        v-if="canMoveAssets"
        show
        variant="info"
      >
        <div class="alert-body">
          <span>You can drag'n'drop assets between organization units.</span>
        </div>
      </b-alert>
      <b-alert
        v-if="!canMoveAssets"
        show
        variant="warning"
      >
        <div class="alert-body">
          <span>You do not have permissions to rearrange assets. This view is readonly for you.</span>
        </div>
      </b-alert>

      <b-overlay :show="isLoading">
        <v-jstree
          :data="treeview"
          size="large"
          allow-transition
          whole-row
          text-field-name="displayName"
          value-field-name="id"
          children-field-name="children"
          :draggable="canMoveAssets"
          @item-drop-before="onItemDropBefore"
        >
          <template scope="_">
            <div
              style="display: inherit; width: 200px"
              @click.ctrl="customItemClickWithCtrl"
              @click.exact="customItemClick(_.vm, _.model, $event)"
            >
              <i
                v-if="_.model.treeviewtype === 'ou' && !_.model.loading"
                :class="_.vm.themeIconClasses"
                role="presentation"
              />
              <feather-icon
                v-if="_.model.treeviewtype === 'asset'"
                icon="BoxIcon"
                class="mr-1"
              />
              <span v-html="_.model.displayName" />
            </div>
          </template>
        </v-jstree>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BAlert } from 'bootstrap-vue'
import VJstree from 'vue-jstree'
import OuService from '@/service/ou.service'
import AssetService from '@/service/asset.service'

export default {
  components: {
    VJstree,
    BOverlay,
    BCard,
    BAlert,
  },
  data() {
    return {
      ous: [],
      assets: [],
      isLoading: false,
    }
  },
  computed: {
    tenantId() {
      return this.$route.params.id
    },
    canMoveAssets() {
      return this.$can('Core.Assets.ManageOrganizationUnits')
    },
    ousIncludingChilds() {
      const result = []
      // add NULL org unit
      result.push({
        id: -1,
        displayName: 'Default',
        parentId: null,
        children: [],
        treeviewtype: 'ou',
        isLeaf: true,
        dragDisabled: true,
      })

      for (let i = 0; i < this.ous.length; i += 1) {
        const ouTreeViewItem = {
          id: this.ous[i].id,
          displayName: this.ous[i].displayName,
          parentId: this.ous[i].parentId,
          children: [],
          treeviewtype: 'ou',
          isLeaf: true,
          dragDisabled: true,
        }

        // find and add assets
        for (let a = 0; a < this.assets.length; a += 1) {
          let assetDisplayName = `${this.assets[a].hostname ?? this.assets[a].systemNumber} / ${this.assets[a].manufacturer} / ${this.assets[a].productFamily}`
          if (this.assets[a].model) {
            assetDisplayName += ` / ${this.assets[a].model}`
          }
          if (this.assets[a].serialnumber) {
            assetDisplayName += ` / SN: ${this.assets[a].serialnumber}`
          }

          if (this.assets[a].status === 2) {
            assetDisplayName += ' / <span class="bg-dark text-light">Expired</span>'
          }
          const assetTreeItem = {
            displayName: assetDisplayName,
            id: this.assets[a].id,
            treeviewtype: 'asset',
            icon: 'fa fa-facebook-f',
            dropDisabled: true,
            dragDisabled: !this.canMoveAssets,
          }
          if (this.assets[a].organizationUnit) {
            if (ouTreeViewItem.id === this.assets[a].organizationUnit.id) {
              ouTreeViewItem.children.push(assetTreeItem)
            }
            // asset has no ou, add it to default ou
          } else if (!result[0].children.some(x => x.id === assetTreeItem.id)) {
            result[0].children.push(assetTreeItem)
          }
        }

        result.push(ouTreeViewItem)
      }

      return result
    },
    treeview() {
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: item.children.concat(nest(items, item.id)), opened: true }))

      return nest(this.ousIncludingChilds)
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true

      Promise.all([
        OuService.getAllListAsync({ impersonateAsTenant: this.tenantId }),
        AssetService.getAllListAsync(null, { impersonateAsTenant: this.tenantId }),
      ])
        .then(result => {
          const [ouResult, assetResult] = result

          this.ous = ouResult.data.items
          this.assets = assetResult
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    onItemDropBefore(node, item, draggedItem, e) {
      switch (draggedItem.treeviewtype) {
        case 'asset':
          this.moveAsset(draggedItem.id, item.id)
          break
        default:
          break
      }
    },
    moveAsset(assetId, ouId) {
      const targetOuId = ouId === -1 ? null : ouId // default ou in treeview has id -1
      AssetService.updateOuAsync(assetId, targetOuId, { impersonateAsTenant: this.tenantId })
        .then(() => {
          this.loadData()
        })
    },
  },
}
</script>
